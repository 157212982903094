import { Config, defaultEnvVariables } from "@env/default";

export const environment: Config = {
  ...defaultEnvVariables,
  production: false,
  API_URL: 'https://apiv2-nprd.cloud.bpifrance.fr/dev/nof/trackmanotif/api',
  WS_URL: 'https://nof.internal.dev.eks-nprd.cloud.bpifrance.fr/tmn',
  SSO: {
    ...defaultEnvVariables.SSO,
    issuer: 'https://authmfamoa.web.bpifrance.fr',
    clientId: 'HmtHAV8Ssv7fTT5ViY2O',
    discoveryDocumentEndpoint: 'mga/sps/oauth/oauth20/metadata/OIDCP_All-Prof_MFA',
    enabled: true,
    tokenType: 'access_token'
  },
};
