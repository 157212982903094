import { notificationPage } from '@env/notification-page-config';
import { ConfigNotificationPage } from '@entity/notification-page.entity';

export const defaultEnvVariables: Config = {
  production: false,
  API_URL: '',
  WS_URL: '',
  SSO: {
    enabled: true,
    issuer: '',
    clientId: '',
    discoveryDocumentEndpoint: '',
    tokenType: "id_token",
  },
  notificationPage,
};

export interface Config {
  production: boolean;
  API_URL: string;
  WS_URL: string;
  SSO: SsoConfig;
  notificationPage: ConfigNotificationPage;
}

export interface SsoConfig {
  enabled: boolean;
  issuer: string;
  clientId: string;
  discoveryDocumentEndpoint: string;
  tokenType: "access_token" | "id_token";
}
