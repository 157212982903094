import { ConfigNotificationPage } from '@entity/notification-page.entity';

export const notificationPage: ConfigNotificationPage = {
  'email-sms': {
    name: 'Email/SMS',
    technicalName: 'email-sms',
    technicalNofTypes: ['EMAIL', 'SMS'],
    headers: [
      'state',
      'sourceId',
      'eventType',
      'productId',
      'receiver',
      'date',
    ],
    filters: [
      {
        name: 'Source Id',
        id: 'sourceId',
        type: 'text',
        placeholder: '13ef4a0c-8c79-45b0-83cb-5ca4e676dfad',
      },
      {
        name: 'Event Type',
        id: 'eventType',
        type: 'text',
        placeholder: 'nof-demoemail',
      },
      { name: 'Product Id', id: 'productId', type: 'text', placeholder: 'NOF' },
      {
        name: 'Destinataire',
        id: 'receiver',
        type: 'text',
        placeholder: 'example@bpifrance.fr',
      },
      { name: 'Date', id: 'date', type: 'date' },
      {
        name: 'État',
        id: 'status',
        type: 'select',
        selectOptions: [
          { value: 'READ', name: 'Lue' },
          { value: 'CONSUME', name: 'Envoyée' },
          { value: 'ERROR', name: 'Erreur' },
          { value: 'WARN', name: 'Warning' },
        ],
      },
    ],
  },
  teams: {
    name: 'Teams',
    technicalName: 'teams',
    technicalNofTypes: ['TEAMS'],
    headers: ['state', 'sourceId', 'productId', 'receiver', 'date'],
    filters: [
      {
        name: 'Source Id',
        id: 'sourceId',
        type: 'text',
        placeholder: '13ef4a0c-8c79-45b0-83cb-5ca4e676dfad',
      },
      { name: 'Product Id', id: 'productId', type: 'text', placeholder: 'NOF' },
      {
        name: 'Destinataire',
        id: 'receiver',
        type: 'text',
        placeholder: 'John Doe',
      },
      { name: 'Date', id: 'date', type: 'date' },
      {
        name: 'État',
        id: 'status',
        type: 'select',
        selectOptions: [
          { value: 'SENT', name: 'Envoyée' },
          { value: 'TO_BE_PROCESSED', name: 'A traiter' },
          { value: 'ERROR', name: 'Erreur' },
        ],
      },
    ],
  },
};
